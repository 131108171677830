import { environment } from 'src/environments/environment';

export class GoogleMaps {
    public static initiazeScript = () => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}&libraries=places&language=en`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
    };
}
