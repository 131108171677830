import { AsyncPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'feedLikes',
    pure: false
})
export class FeedLikesPipe implements PipeTransform {
    transform(value: any): string {
        let retVal = "Liked by ";
        const allUsers = value;
        if (allUsers.length) {
            if (allUsers.length > 2)
                retVal += `${allUsers[allUsers.length - 1]} and ${allUsers.length - 1} others`
            else if (allUsers.length === 2)
                retVal += `${allUsers[allUsers.length - 1]} and ${allUsers[0]}`
            else
                retVal += `${allUsers[allUsers.length - 1]}`
            return retVal;
        } else {
            return "";
        }
    }
}
