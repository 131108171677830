import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import * as _ from 'lodash';
import { first } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DomainService {
    constructor(private firebase: FirebaseService) {}
    getCustomerId(domain: string) {
        return new Promise((resolve, reject) => {
            domain = domain.replace(/\./g, '_');
            const ref = `customDomains/${domain}`;
            this.firebase
                .getByRef(ref)
                .pipe(first())
                .subscribe((customerId: any) => {
                    resolve(customerId || '');
                });
        });
    }
}
