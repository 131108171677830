import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as lodash from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class EventTrasformerService {
    moment = moment;
    _ = lodash;
    formats = [
        { db: 'd/m/Y', app: 'DD/MM/YYYY' },
        { db: 'm/d/Y', app: 'MM/DD/YYYY' },
        { db: 'Y/m/d', app: 'YYYY/MM/DD' },
        { db: 'H:i', app: 'H:mm' },
        { db: 'h:i a', app: 'h:mm a' },
    ];
    // constructor() {}

    transformSingle(item: any) {
        if (!item) {
            return;
        }
        const transoformedItem = item;

        item.startDatetime = item.start_date ? this.moment(item.start_date.replace(' ', 'T')).toDate() : undefined;
        if (!item.start_date) {
            item.start_date = null;
        }
        item.endDatetime = item.end_date ? this.moment(item.end_date.replace(' ', 'T')).toDate() : undefined;
        if (!item.end_date) {
            item.end_date = null;
        }

        if (item.time_format) {
            //find in formats
            // let format = this._.find(this.formats, { db: item.time_format });
            const format = this.formats.find((f) => f.db === item.time_format);
            if (format) {
                transoformedItem.time_format = format.app;
            } else {
                transoformedItem.time_format = 'H:mm';
            }
        }

        if (item.date_format) {
            //find in formats
            // let format = this._.find(this.formats, { db: item.date_format });
            const format = this.formats.find((f) => f.db === item.date_format);
            if (format) {
                transoformedItem.date_format = format.app;
            } else {
                transoformedItem.date_format = 'd/M/yyyy';

                // console.log('')
            }
        }
        if (item.container) {
            transoformedItem.container = item.container.length ? item.container : null;
        }
        if (item.event_sec && item.event_sec === 'password') {
            transoformedItem.event_sec_enabled = true;
        } else {
            transoformedItem.event_sec_enabled = false;
        }
        return transoformedItem;
    }

    transform(event: any) {
        // console.log('EVENT', event);
        // console.log('=====event====', event.date_format)
        return {
            id: event.id,
            // about_title: event.about_title,
            event_code: event.id,
            title: event.title,
            check_in: event.check_in || false,
            container: event.container || '',
            description: event.description || '',
            start_date: event.start_date_time_unix,
            end_date: event.end_date_time_unix,
            startDatetime: event.startDatetime || '',
            endDatetime: event.endDatetime || '',
            status: event.status,
            event_sec: event.event_sec,
            event_sec_password: event.event_sec_password,
            date_format: event.date_format,
            time_format: event.time_format,
            time_zone: event.time_zone,
            color: event.colour_scheme,
            icon: event.app_icon,
            icon_small: event.app_icon_small,
            splash: event.app_splashscreen,
            splash_small: event.app_splashscreen_small,
            add_terms: event.add_terms,
            terms_info: event.terms_info,
            terms_url: event.terms_url,
            is_paid: event.is_paid,
            pairing_key: event.pairing_key,
            event_feed_key: event.event_feed_key,
            topic_arn: event.topic_arn,
            total_attendees: event.total_attendees || 0,
            // firebase_token: event.firebase_token,
            is_attendees_enabled: event.is_attendees_enabled,
            is_attendees_paid: event.is_attendees_paid,
            is_interactivity_enabled: event.is_interactivity_enabled,
            is_interactivity_paid: event.is_interactivity_paid,
            is_banner_advertising_enabled: event.is_banner_advertising_enabled,
            is_banner_advertising_paid: event.is_banner_advertising_paid,
            is_professional_enabled: event.is_professional_enabled,
            is_professional_paid: event.is_professional_paid,
            session_selection: event.session_selection,
            session_selection_from_date: event.session_selection_from_date,
            home_page_type: event.home_page_type,
            collect_event_feedback: event.collect_event_feedback,
            poll_colour1: event.poll_colour1,
            poll_colour2: event.poll_colour2,
            poll_colour3: event.poll_colour3,
            poll_colour4: event.poll_colour4,
            poll_colour5: event.poll_colour5,
            poll_colour6: event.poll_colour6,
            is_promo_phones_ready: event.is_promo_phones_ready,
            promo_phones_image_url: event.promo_phones_image_url,
            is_promo_social_ready: event.is_promo_social_ready,
            promo_social_image_url: event.promo_social_image_url,
            qr_code_image_url: event.qr_code_image_url,
            qr_scanning: event.qr_scanning,
            sponsors_qr_codes_url: event.sponsors_qr_codes_url,
            is_sponsors_qr_codes_ready: event.is_sponsors_qr_codes_ready,
            is_promo_posters_ready: event.is_promo_posters_ready,
            promo_poster_a3_image_url: event.promo_poster_a3_image_url,
            promo_poster_a4_image_url: event.promo_poster_a4_image_url,
            promo_poster_a5_image_url: event.promo_poster_a5_image_url,
            promo_posters_zip_url: event.promo_posters_zip_url,
            is_promo_banners_ready: event.is_promo_banners_ready,
            is_new_event: event.is_new_event,
            promo_banner_160x600_image_url: event.promo_banner_160x600_image_url,
            promo_banner_300x250_image_url: event.promo_banner_300x250_image_url,
            promo_banner_300x600_image_url: event.promo_banner_300x600_image_url,
            promo_banner_320x110_image_url: event.promo_banner_320x110_image_url,
            promo_banner_336x280_image_url: event.promo_banner_336x280_image_url,
            promo_banner_728x90_image_url: event.promo_banner_728x90_image_url,
            promo_banners_zip_url: event.promo_banners_zip_url,
            promo_emailer_text: event.promo_emailer_text,
            promo_emailer_footer_text: event.promo_emailer_footer_text,
            featured: event.featured,
            visible: event.visible,
            uploaded_artwork: event.uploaded_artwork,
            uploaded_artwork_transparent: event.uploaded_artwork_transparent,
            unpublished: event.unpublished || null,
            created_at: event.created_at,
            questions: event.questions,
            venue: {
                title: event.venue,
                geo_long: event.geo_long,
                geo_lat: event.geo_lat,
            },
            user_id: event.user_id,
            virtual: event.virtual || false,
            artworks_type: event.artworks_type || 'standard',
            about_title: event.about_title || 'About',
            is_success_msg_seen: event.is_success_msg_seen,
            location: event.location || '',
            type: event.type || '',
            category: event.category || '',
            not_sure_yet: event.not_sure_yet || false,
            summary: event.summary || '',
            isMobileAppEnable: event.isMobileAppEnable || false,
            isEventWebSiteEnable: event.isEventWebSiteEnable || false,
            isRegistrationEnable: event.isRegistrationEnable || false,
            isTicketAndRegistrationEnable: event.isTicketAndRegistrationEnable || false,
            isEventFeedbackEnable: event.isEventFeedbackEnable || false,
            isMarketingEnable: event.isMarketingEnable || false,
            locationType: event.locationType || false,
            app_id: event.app_id || '',
            totalAmount: event.totalAmount || null,
            planCurrency: event.planCurrency || null,
            plan: event.plan || null,
            numberOfUsers: event.numberOfUsers || null,
            numbersOfAttendees: event.numbersOfAttendees || null,
            colours: event.colours || null,
            headerFontFile: event.headerFontFile || null,
            bodyFontFile: event.bodyFontFile || null,
            headerFonts: event.headerFonts || null,
            bodyFonts: event.bodyFonts || null,
            style: event.style || null,
        };
    }

    prepareThemeData(data: any) {
        return {
            colour_scheme: data.color || '#cccccc',
            colour_scheme_secondary: data.seccolor || '#00b4ce',
            app_icon: data.icon || null,
            app_icon_small: data.icon_small || null,
            app_splashscreen: data.splash || null,
            app_splashscreen_small: data.splash_small || null,
            artworks_type: data.artworks_type || 'standard',
        };
    }

    prepareData(data: any) {
        const prepared: any = {
            title: data.title || '',
            venue: data.venue_title || '',
            // home_page_type: data.home_page_type || null,
            time_zone: data.time_zone || '',
            // container: data.container || null,
            start_date_time_unix: moment(data.start_date).toDate() || '',
            end_date_time_unix: moment(data.end_date).toDate() || '',
            // featured: data.featured || false,
            // visible: typeof data.visible !== 'undefined' ? data.visible : true,
            // event_sec: data.event_sec_enabled ? 'password' : '',
            check_in: data.check_in || false,
            virtual: data.virtual || false,
            type: data.type || '',
            category: data.category || '',
        };
        if (data.description) {
            prepared.description = data.description;
        } else {
            prepared.description = '';
        }
        if (data.venue_geo_lat) {
            prepared.geo_lat = data.venue_geo_lat;
        }
        if (data.venue_geo_long) {
            prepared.geo_long = data.venue_geo_long;
        }
        if (data.time_format) {
            //find in formats

            const format = this._.find(this.formats, { app: data.time_format });
            if (format) {
                prepared.time_format = format.db;
            } else {
                prepared.time_format = 'H:i';
            }
        }
        if (data.date_format) {
            //find in format
            const format = this._.find(this.formats, { app: data.date_format });
            if (format) {
                prepared.date_format = format.db;
            } else {
                prepared.date_format = 'd/m/Y';
            }
        }
        if (data.venue) {
            prepared.locationId = data.locationId;
        }

        // if(data.)

        return prepared;
    }

    prepareEventDate(data: any) {
        const prepared: any = {
            start_date_time_unix: moment(data.start_date).toDate() || '',
            end_date_time_unix: moment(data.end_date).toDate() || '',
        };

        return prepared;
    }

    preparePrivateData(data: any) {
        return {
            event_sec_password: data.event_sec_password,
        };
    }

    prepareAccessibilityData(data: any) {
        return {
            event_sec: data.event_sec_enabled ? 'password' : '',
            visible: data.visible,
            home_page_type: data.home_page_type || 'SOCIAL',
            session_selection: data.session_selection,
        };
    }
}
