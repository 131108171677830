import { Injectable } from '@angular/core';
import { feedBack } from '../models/feedBack.modal';
import { Transformer } from '../abstract-components/transformer';

@Injectable({
    providedIn: 'root',
})
export class CustomerEventFeedbackTransformerService extends Transformer {
    override transformSingle(item: feedBack[]) {
        // console.log('items', item);
        return item;
    }

    setPageType(type: string) {
        this.pageType = type;
    }
}
