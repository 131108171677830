import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { AppEvent } from 'src/app/models/app.modal';
import { CustomerEventFeedbackService } from 'src/app/services/customer-event-feedback.service';
import { CustomerService } from 'src/app/services/customer.service';
import { FilestackService } from 'src/app/services/filestack.service';
import { DomainService } from '../services/domain.service';
import { PlatformLocation } from '@angular/common';

@Component({
    selector: 'app-event-not-available',
    templateUrl: './event-not-available.component.html',
    styleUrls: ['./event-not-available.component.scss'],
})
export class EventNotAvailableComponent implements OnInit {
    isLoading = true;
    customerId = '';
    eventId = '';
    event!: AppEvent;
    imgTree: any;
    constructor(
        private fb: FormBuilder,
        private customerService: CustomerService,
        private route: ActivatedRoute,
        private fileStack: FilestackService,
        private feedbackService: CustomerEventFeedbackService,
        private domainService: DomainService,
        private platformLocation: PlatformLocation
    ) {}

    ngOnInit(): void {
        this.route.params.subscribe((p: Params) => {
            // console.log('p', p);
            this.customerId = p['customerId'];
            this.eventId = p['eventId'];
            const match = this.platformLocation.href.match(/\/\/(.*?)\//);
            const domain = match ? match[1] : '';
            console.log(match ? match[1] : '');
            this.domainService.getCustomerId(domain).then((customerId) => {
                if (!this.customerId) {
                    this.customerId = customerId as string;
                }
                console.log('CUstomer ID is', this.customerId);
                this.customerService.getEvent(this.customerId, this.eventId).subscribe((event) => {
                    this.event = event;
                    this.imgTree = this.fileStack.splitEncodedUrl(this.event?.icon, 10, 100, 100, 'fit');
                    this.isLoading = false;
                });
            });
        });
    }

    customTitleCase(value: string | null | undefined): string {
        const returnVal: string[] = [];
        if (typeof value !== 'string') {
            return '';
        }
        const words = value.split(' ');
        for (const word of words) {
            if (word === word.toUpperCase()) {
                returnVal.push(word);
            } else {
                returnVal.push(this.toTitleCase(word));
            }
        }
        return returnVal.join(' ');
    }

    toTitleCase(input: string): string {
        return input.length === 0
            ? ''
            : input.replace(/\w\S*/g, (txt) => txt[0].toUpperCase() + txt.slice(1).toLowerCase());
    }
}
