import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { SetupMenuService } from './setup-menu.service';
import { Observable, catchError, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { push } from '@angular/fire/database';
import { Airports } from '../models/airport.modal';
@Injectable({
    providedIn: 'root',
})
export class UtilityService {
    constructor(private http: HttpClient) {}

    getCountry() {
        return this.http.get('https://ipapi.co/json/').pipe(
            map((val) => val),
            catchError((err: any): any => {
                console.log('err==========', err);
                return of([]);
            })
        );
    }

    resizeInput(value: string, el: HTMLLabelElement) {
        // const el = el
        el.dataset['value'] = value;
        // el.classList.add('input-1')
        return value;
    }

    getCountryCodes() {
        return this.http.get('assets/country-code.json');
    }

    getCountryState() {
        return this.http.get('assets/country-states.json');
    }

    getCountryCities() {
        return this.http.get('assets/countries+cities.json');
    }

    generateUID(length = 5) {
        let pool1: any = 'ABCDEFGHIJKLMNOPQRSTUVQWXYZ';
        let pool2: any = '123456789ABCDEFGHIJKLMNOPQRSTUVQWXYZ';
        let shuffled = '';
        let charIndex = 0;
        pool1 = pool1.split('');
        pool2 = pool2.split('');
        const firstLetterIndex = Math.floor(pool1.length * Math.random());
        while (pool2.length > 0) {
            charIndex = Math.floor(pool2.length * Math.random());
            shuffled += pool2[charIndex];
            pool2.splice(charIndex, 1);
        }
        return pool1[firstLetterIndex] + shuffled.substring(0, length - 1);
    }

    prepareURL(data: string) {
        if (data) {
            if (data.indexOf('http') !== -1 || data.indexOf('https') !== -1) {
                return data;
            } else {
                return 'http://' + data;
            }
        } else {
            return '';
        }
    }

    urlify(text: string) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const urlString = text.replace(urlRegex, '<a target="_blank" href="$1">$1</a>');
        const stringtoHtml = '<p>' + urlString + '</p>';
        return stringtoHtml;
    }

    getVideoInfo(videoUrl: string) {
        return this.http.get(`https://www.youtube.com/oembed?url=${videoUrl}&format=json`, {
            headers: new HttpHeaders({
                'Content-Type': 'text',
            }),
        });
    }

    getVimeoVideoInfo(videoUrl: string) {
        return this.http.get(`https://vimeo.com/api/oembed.json?url=${videoUrl}`);
    }

    getCurrencySymbol(code: string, isOnlySymbol = true) {
        return new Promise((resolve, reject) => {
            this.http.get('assets/currency-code.json').subscribe((DATA: any) => {
                if (isOnlySymbol) {
                    resolve(DATA[code]?.symbol);
                } else {
                    resolve(DATA[code]);
                }
            });
        });
    }

    getCurrency() {
        return this.http.get('assets/currency-code.json');
    }

    getTimezoneFromIata(iata: string): Promise<string> {
        return new Promise((resolve, reject) => {
            this.http.get('assets/iata-codes.json').subscribe((DATA: any) => {
                DATA.some((data: { iata: string; timezone: string }) => {
                    if (data.iata === iata) {
                        resolve(data.timezone);
                        return data.iata === iata;
                    } else {
                        return false;
                    }
                });
                resolve('Africa/Johannesburg');
            });
        });
    }

    getAriPortName(): Observable<Airports[]> {
        return (this.http.get('assets/airports.json') as Observable<Airports[]>).pipe(map((resp) => resp));
    }

    getStateCode(state: string) {
        if (state) {
            return this.http.get('assets/country-states.json').pipe(
                map((values: any) => {
                    // console.log('====Values', values);
                    return values;
                })
            );
        }

        return of(null);
    }

    transformToTitleCase(input: string): string {
        if (!input) return '';
        return input.length === 0
            ? ''
            : input.replace(/\w\S*/g, (txt) => txt[0].toUpperCase() + txt.slice(1).toLowerCase());
    }

    checkIfColorIsDark(hexCode: string) {
        const c = hexCode.substring(1); // strip #
        const rgb = parseInt(c, 16); // convert rrggbb to decimal
        const r = (rgb >> 16) & 0xff; // extract red
        const g = (rgb >> 8) & 0xff; // extract green
        const b = (rgb >> 0) & 0xff; // extract blue

        const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

        return luma < 128;
    }

    getTimeZoneWithUnderScore(timeZone: string) {
        const [utcString, timeZoneCountry] = timeZone.split(') ');
        return utcString + ') ' + timeZoneCountry.replace(' ', '_');
    }

    convertToBase64(file: any) {
        return new Promise((resolve) => {
            //Read File
            const selectedFile = file;
            //Check File is not Empty
            if (selectedFile) {
                // Select the very first file from list
                const fileToLoad = selectedFile;
                // FileReader function for read the file.
                const fileReader = new FileReader();
                let base64;
                // Onload of file read the file content
                fileReader.onload = (fileLoadedEvent: any) => {
                    base64 = fileLoadedEvent.target.result;
                    // Print data in console
                    resolve(base64.split(',')[1]);
                };
                // Convert data to base64
                fileReader.readAsDataURL(fileToLoad);
            }
        });
    }

    getGoogleFonts(): Promise<{
        fontWeights: { [key: string]: { value: number; label: number }[] };
        fontFiles: { [key: string]: { [key: number]: string } };
        fontsArr: { value: string; label: string }[];
    }> {
        return new Promise((resolve, reject) => {
            return this.http
                .get(
                    `https://www.googleapis.com/webfonts/v1/webfonts?key=${environment.googleMapsApiKey}&sort=popularity`
                )
                .subscribe((res: any) => {
                    if (res) {
                        let allFonts = res.items;
                        allFonts = allFonts.slice(0, 100);
                        const fontFiles: any = {};
                        const fontWeights: { [key: string]: { value: number; label: number }[] } = {};
                        const fontsArr: { value: string; label: string }[] = [];
                        allFonts.map(
                            (font: { variants: string[]; files: { [key: string]: string }[]; family: string }) => {
                                fontsArr.push({ value: font.family, label: font.family });
                                if (font.variants) {
                                    font.variants.map((variant) => {
                                        if (!fontWeights[font.family]) {
                                            fontWeights[font.family] = [];
                                        }
                                        if (!fontFiles[font.family]) {
                                            fontFiles[font.family] = {};
                                        }
                                        if (!isNaN(Number(variant))) {
                                            fontWeights[font.family].push({
                                                value: Number(variant),
                                                label: Number(variant),
                                            });
                                            fontFiles[font.family][Number(variant)] = font.files[variant as any];
                                        } else if (variant === 'regular') {
                                            fontWeights[font.family].push({
                                                value: 400,
                                                label: 400,
                                            });
                                            fontFiles[font.family][400] = font.files[variant as any];
                                        }
                                    });
                                }
                            }
                        );
                        console.log('TOP 100 FONTS ARE', allFonts);
                        console.log('TOP 100 FONTS Wights', fontWeights);
                        console.log('TOP 100 FONTS FILES', fontFiles);
                        console.log('TOP 100 FONTS Arr', fontsArr);
                        resolve({ fontWeights, fontFiles, fontsArr });
                    } else {
                        resolve({ fontWeights: {}, fontFiles: {}, fontsArr: [] });
                    }
                });
        });
    }

    LightenDarkenColor(col: string, amt: number) {
        col = col.includes('#') ? col.substring(1) : col;
        const colCode = parseInt(col, 16);
        return (
            '#' +
            (
                ((colCode & 0x0000ff) + amt) |
                ((((colCode >> 8) & 0x00ff) + amt) << 8) |
                (((colCode >> 16) + amt) << 16)
            ).toString(16)
        );
    }

    getLinkName(url: string) {
        if (url.startsWith('https://meet.google.com') || url.startsWith('http://meet.google.com')) {
            return 'Google Meet Meeting';
        } else if (url.startsWith('https://zoom.us') || url.startsWith('http://zoom.us')) {
            return 'Zoom Meeting';
        } else if (url.startsWith('https://teams.microsoft.com') || url.startsWith('http://teams.microsoft.com')) {
            return 'Microsoft Teams Meeting';
        } else {
            return 'Online Meeting';
        }
    }
}
