/* eslint-disable @typescript-eslint/no-explicit-any */
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { DatabaseReference } from '@angular/fire/compat/database/interfaces';
import * as lodash from 'lodash';
import { Category } from '../models/category.modal';

export class AbstarckPage {
    appRef!: DatabaseReference;
    publicRef!: DatabaseReference;
    dataRef!: DatabaseReference;
    sessionsRef!: DatabaseReference;
    listRef!: DatabaseReference;
    appId!: string;
    eventId!: string;
    entityPathName = '';
    entityName = '';
    entityType = '';

    _ = lodash;

    constructor(public transformer: any, public db: AngularFireDatabase) {}

    create(data: any) {
        return new Promise((resolve) => {
            const rawData = this.transformer.prepareData(data);
            const newEntityId = this.dataRef.child(this.entityPathName).push().key as string;
            this.dataRef
                .child(this.entityPathName)
                .child(newEntityId)
                .update(rawData, () => {
                    data.id = newEntityId;
                    this.updateTotal(this.entityPathName);
                    resolve(data);
                });
        });
    }

    updateTotal(pathName: string) {
        this.dataRef
            .child(pathName)
            .once('value')
            .then((snapshot) => {
                const len = this._.size(snapshot.val()) || 0;
                this.listRef.child(`total_${pathName}`).set(len);
            });
    }

    // updateTotal() {
    //     this.dataRef.child('attendees').once('value', (snapshot) => {
    //         let attendees = this._.size(snapshot.val());
    //         this.listRef.child(`total_attendees`).set(attendees, () => {
    //             this.$log.log('Attendees total was updated to:', attendees);
    //         });
    //     });
    // }

    update(data: any) {
        return new Promise((resolve) => {
            const rawData = this.transformer.prepareData(data);
            this.dataRef
                .child(this.entityPathName)
                .child(data.id)
                .update(rawData, () => {
                    resolve(Object.assign(data, rawData));
                });
        });
    }

    remove(data: any) {
        return new Promise((resolve, reject) => {
            if (data.id) {
                this.dataRef.child(`/${this.entityPathName}/${data.id}`).set(null, () => {
                    this.updateTotal(this.entityPathName);
                    resolve(true);
                });
            } else {
                reject();
            }
        });
    }

    getCategories(itemType: string) {
        return new Promise((resolve) => {
            this.dataRef
                .child(`${itemType}_categories`)
                .orderByChild('category_order')
                .once('value')
                .then((snapshot) => {
                    let categories: any[] = [];
                    const rawData = snapshot.val();
                    if (rawData) {
                        this._.forOwn(rawData, (val, key) => {
                            val.id = key;
                            categories.push(val);
                        });
                    }
                    if (categories.length) {
                        categories = this._.sortBy(categories, ['category_order'], ['asc']);
                    }

                    resolve(categories);
                });
        });
        // return def.promise;
    }

    addCategory(item: Category) {
        return new Promise((resolve) => {
            const categoriesRef = this.dataRef.child(`${this.entityType}_categories`);
            categoriesRef.once('value').then((snapshot) => {
                const order = this._.size(snapshot.val()) || 0;
                const newCatRef = categoriesRef.push();
                newCatRef.set(
                    {
                        name: item.name,
                        category_order: order,
                    },
                    () => {
                        item.category_order = order;
                        item.id = newCatRef.key;
                        resolve(item);
                    }
                );
            });
        });
    }

    updateCategory(item: Category, entityType: string) {
        const data = { name: item.name } as Category;
        if (item.category_order !== undefined) {
            data.category_order = item.category_order;
        }
        return this.dataRef
            .child(`${entityType}_categories`)
            .child(item.id as string)
            .update(data);
    }
}
