/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable, combineLatest, first, map } from 'rxjs';
import * as _ from 'lodash';
import { AppEvent } from '../models/app.modal';
import { EventTrasformerService } from './event-trasformer.service';

@Injectable({
    providedIn: 'root',
})
export class FirebaseService {
    constructor(
        private db: AngularFireDatabase,
        private eventTransformer: EventTrasformerService,
        private storage: AngularFireStorage
    ) {}

    getByRef(refrence: string): Observable<any> {
        return this.db.object(refrence).valueChanges();
    }

    removeByRef(refrence: string): Promise<any> {
        return this.db.object(refrence).remove();
    }

    getCustomers() {
        return this.db.list('customers').valueChanges();
    }

    getCustomerById(id: string) {
        return this.db.object(`customers/${id}`).valueChanges();
    }

    getDbUserByUserId(id: string) {
        if (!isNaN(Number(id))) {
            const userId = Number(id);
            return this.db
                .list('users', (ref) => ref.orderByChild('user_id').equalTo(userId))
                .snapshotChanges()
                .pipe(
                    map((changes) =>
                        changes.map((c: any) => {
                            const user = c.payload.val();
                            const id = c.payload.key;
                            user.id = id;
                            return user;
                        })
                    )
                );
        } else {
            return this.getByRef(`users/${id}`).pipe(
                map((owner: any) => {
                    if (owner) {
                        const user = owner;
                        user.id = id;
                        return user;
                    } else {
                        return null;
                    }
                })
            );
        }
        // return
    }

    setByRef(reference: string, data: any) {
        return new Promise((resolve, reject) => {
            // if (data) {
            this.db.database
                .ref(reference)
                .set(data, () => {
                    resolve(true);
                })
                .catch((e) => {
                    console.log('ERROR =========', e);
                    reject(true);
                });
            // } else {
            //     resolve(true);
            // }
        });
    }

    getDbUser(uid: string) {
        return new Promise((resolve, reject) => {
            this.db.database
                .ref()
                .child(`users/${uid}`)
                .on(
                    'value',
                    (snapshot) => {
                        const user = snapshot.val();
                        if (user) {
                            user.id = uid;
                            resolve(user);
                        } else {
                            reject('User not found');
                        }
                    },
                    (error) => {
                        console.log(error);
                        reject(error);
                    }
                );
        });
    }

    getDbUserByEmail(email: string) {
        return new Promise((resolve, reject) => {
            this.db.database
                .ref()
                .child('users')
                .orderByChild('email')
                .equalTo(`${email}`)
                .on(
                    'value',
                    (snapshot) => {
                        const data = snapshot.val();
                        if (data) {
                            const user = _.values(data)[0];
                            const userId = _.keys(data)[0];
                            user.id = userId;

                            resolve(user);
                        } else {
                            resolve(null);
                        }
                    },
                    (error) => {
                        console.log(error);
                        reject(error);
                    }
                );
        });
    }

    pushByRef(reference: string, data: any, addId = false) {
        // console.log('reference', reference);
        return new Promise((resolve, reject) => {
            const ref = this.db.database.ref();
            const newRef = ref.child(reference).push();
            const key = newRef.key as string;
            if (addId) {
                data.id = key;
            }
            ref.child(reference)
                .child(key)
                .update(data)
                .then(() => {
                    return resolve(key);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    assignAttendees(appId: string, eventId: string, pageId: string, ids: string[]) {
        return new Promise((resolve) => {
            const promises: any[] = [];
            ids.forEach((id) => {
                promises.push(this._assignAttendee(appId, eventId, pageId, id));
            });
            Promise.all(promises).then(() => {
                resolve(true);
            });
        });
    }

    _assignAttendee(appId: string, eventId: string, pageId: string, id: string) {
        return new Promise((resolve) => {
            if (id) {
                this.db.database
                    .ref()
                    .child('apps')
                    .child(appId)
                    .child('public')
                    .child('events_data')
                    .child(eventId)
                    .child('attendees')
                    .child(id)
                    .update({ page_id: pageId }, () => {
                        resolve(true);
                    });
            }
        });
    }

    updateByRef(reference: string, data: any) {
        // console.log('=====reference', reference);
        return new Promise((resolve, reject) => {
            // console.log('update', reference, data);
            this.db.database
                .ref()
                .child(reference)
                .update(data, (error) => {
                    if (error) {
                        reject(error);
                    }
                    resolve(true);
                });
        });
    }
    unassignAttendees(appId: string, eventId: string, pageId: string) {
        return new Promise((resolve) => {
            const attendeesRef = `apps/${appId}/public/events_date/${eventId}/attendees/${pageId}`;
            this.db.list(`${attendeesRef}`, (ref): any => {
                return ref
                    .orderByChild('page_id')
                    .equalTo(pageId)
                    .once('value', (snapshot: any) => {
                        const promises: any[] = [];
                        _.keys(snapshot.val()).forEach((key) => {
                            promises.push(this._unassignAttendee(appId, eventId, key));
                        });
                        Promise.all(promises).then(() => {
                            resolve(true);
                        });
                    });
            });
        });
    }

    createPromoTask(task: any, app_id: string, event_id: string) {
        return new Promise((resolve) => {
            this.db.database.ref('queue/tasks').push(
                {
                    _state: task,
                    app_id,
                    event_id,
                },
                () => {
                    resolve(true);
                }
            );
        });
    }

    _unassignAttendee(appId: string, eventId: string, id: string) {
        return new Promise((resolve) => {
            const attendeesRef = `apps/${appId}/public/events_date/${eventId}/attendees/${id}`;
            this.db
                .object(`${attendeesRef}`)
                .update({ page_id: 'unassigned' })
                .then(() => resolve(true));
        });
    }

    getByRefWhere(reference: string, child: string, equalTo: any) {
        return new Promise((resolve, reject) => {
            this.db.database
                .ref(reference)
                .orderByChild(child)
                .equalTo(equalTo)
                .on(
                    'value',
                    (snapshot) => {
                        resolve(snapshot.val());
                    },
                    (error) => {
                        console.log('error', error);
                        reject(error);
                    }
                );
        });
    }

    getContributesEventsByUser(appId: string, userId: string) {
        return new Promise((resolve) => {
            console.log(' - getContributesEventsByUser', appId, userId);
            const requestUrl =
                appId === 'HELLOCROWD'
                    ? `users/${userId}/hc_events`
                    : `users/${userId}/contribute_apps_events/${appId}`;
            this.db.database.ref(requestUrl).on('value', (snapshot) => {
                const ids = _.keys(snapshot.val());
                console.log(' -- ', ids);
                if (ids && ids.length) {
                    const results: any = {};

                    const observables$: any = [];
                    ids.forEach((id: string) => {
                        observables$.push(this.getByRef(`apps/${appId}/public/events_list/${id}`));
                    });

                    (combineLatest(observables$).pipe(first()) as Observable<AppEvent[]>).subscribe((events) => {
                        events.forEach((event: AppEvent, i: number) => {
                            if (event) {
                                results[ids[i]] = event;
                            }
                        });
                        resolve(results);
                    });
                } else {
                    console.log('resolve null');
                    resolve(null);
                }
            });
        });
    }

    createScheduledNotificationTask(appId: string, eventId: string, messageId: string) {
        return new Promise((resolve) => {
            const tasksRef = this.db.database.ref('queue/tasks');
            tasksRef.push(
                {
                    _state: 'notification_schedule',
                    app_id: appId,
                    event_id: eventId,
                    notification_message_id: messageId,
                },
                () => {
                    resolve(true);
                }
            );
        });
    }

    getEventsByUserId(appId: string, userId: string) {
        return new Promise((resolve, reject) => {
            // console.log('-----------------GET EVENTS', appId, userId, userId2);
            // if (appId === 'HELLOCROWD') {
            const promises: any[] = [
                this.getEventByUser(appId, userId),
                this.getContributesEventsByUser(appId, userId),
            ];
            Promise.all(promises).then((values) => {
                const data = _.merge(values[0], values[1]);
                // if (values[2]) {
                //     data = _.merge(data, values[2]);
                // }
                const event = {
                    ownEvent: values[0],
                    contributoreEvent: values[1],
                };
                resolve(data);
            });
            // } else {
            //     this.getEventByUser(appId, userId).then((data) => {
            //         resolve(data);
            //     });
            //     // this.getByRef(`apps/${appId}/public/events_list`).then((data) => resolve(data));
            // }
        });
    }

    createScavengerHuntTask(app_id: string, event_id: string, scavenger_hunt_id: string) {
        return new Promise((resolve) => {
            this.db.database.ref('queue/tasks').push(
                {
                    _state: 'generate_scavenger_hunt_images',
                    app_id,
                    event_id,
                    scavenger_hunt_id,
                },
                () => {
                    resolve(true);
                }
            );
        });
    }

    getEventByUser(appId: string, userId: string) {
        if (appId === 'HELLOCROWD') {
            return new Promise((resolve, reject) => {
                // console.log(' - GET EVENT BY USER', appId, userId);
                this.db.database
                    .ref()
                    .child(`apps/${appId}/public/events_list`)
                    .orderByChild('user_id')
                    .equalTo(`user${userId}`)
                    .on('value', (snapshot) => {
                        const event: any[] = [];
                        _.forOwn(snapshot.val(), (val: any, key) => {
                            if (val && key) {
                                val.id = key;
                                val.app_id = appId;
                                // if (val) {
                                // }

                                event.push(this.eventTransformer.transformSingle(this.eventTransformer.transform(val)));
                                // event.push(val);
                            }
                        });
                        resolve(event);
                    });
            });
        } else {
            return new Promise((resolve, reject) => {
                // console.log(' - GET EVENT BY USER', appId, userId);
                this.db.database
                    .ref()
                    .child(`apps/${appId}/public/events_list`)
                    .on('value', (snapshot) => {
                        const event: any[] = [];
                        _.forOwn(snapshot.val(), (val: any, key) => {
                            if (val && key) {
                                val.id = key;
                                val.app_id = appId;
                                event.push(this.eventTransformer.transformSingle(this.eventTransformer.transform(val)));
                                // event.push(val);
                            }
                        });
                        resolve(event);
                    });
            });
        }
    }

    // getEventByUser$(appId: string, userId: string) {
    //     return this.db.object().
    // }

    createStripeSubscription(subscriptionObject: any) {
        return new Promise((resolve, reject) => {
            const newSub = this.db.database.ref().child('stripe_subscriptions').push();
            const subscription_id = newSub.key;
            this.setByRef(`stripe_subscriptions/${subscription_id}`, subscriptionObject).then(() => {
                resolve({
                    subscription_id,
                    subscription: subscriptionObject,
                });
            });
            // newSub.update(subscriptionObject, () => {});
        });
    }

    findUserByActivationCode(activation_code: string) {
        return new Promise((resolve, reject) => {
            const ref = this.db.database.ref();
            ref.child('users')
                .orderByChild('activation_code')
                .equalTo(activation_code)
                .once(
                    'value',
                    (snapshot) => {
                        const data = snapshot.val();
                        if (data) {
                            const user = _.values(data)[0];
                            const userId = _.keys(data)[0];
                            user.id = userId;
                            resolve(user);
                        } else {
                            reject('Activation Code is invalid.');
                        }
                    },
                    (error) => {
                        console.log(error);
                        reject(error);
                    }
                );
        });
    }

    uploadSources(file: any) {
        // for (let file of files) {
        return new Promise((resolve, reject) => {
            const newFileName = Date.now() + '-report.xlsx';
            const filePath = `files/${newFileName}`;
            const storageRef = this.storage.ref(filePath);
            this.storage.upload(filePath, file).then(() => {
                storageRef.getDownloadURL().subscribe((downloadURL) => {
                    const fileData = {
                        url: downloadURL,
                        name: file.name,
                        size: file.size,
                        newFileName: newFileName,
                    };

                    // console.log('=====', downloadURL);
                    // console.log('====fileData', fileData);

                    resolve(fileData);

                    // return fileData;
                });
            });
            // uploadTask.snapshotChanges().pipe(
            //     map((data: any) => {
            //         console.log('==data', data);
            //     })
            // );
        });
    }
}
