import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackBarService } from 'src/app/services/snack-bar.service';

@Component({
    selector: 'app-snack-bar',
    templateUrl: './snack-bar.component.html',
    styleUrls: ['./snack-bar.component.scss'],
})
export class SnackBarComponent {
    constructor(
        public sbRef: MatSnackBarRef<SnackBarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
        private snackBarService: SnackBarService
    ) {}

    dismissNotification() {
        this.snackBarService.dismissSnackBar();
    }
}
