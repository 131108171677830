import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideDatabase, getDatabase } from '@angular/fire/database';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import {
    DecimalPipe,
    IMAGE_CONFIG,
    IMAGE_LOADER,
    ImageLoaderConfig,
    Location,
    isPlatformBrowser,
} from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { InitialPipe } from './pipes/initial.pipe';
import { NgOptimizedImage } from '@angular/common';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { Loader } from '@googlemaps/js-api-loader';
import { FeedTimePipe } from './pipes/feed-time.pipe';
import { FeedLikesPipe } from './pipes/feed-likes.pipe';
import { EventNotAvailableComponent } from './event-not-available/event-not-available.component';
import * as _ from 'lodash';
import { TranslocoRootModule } from './transloco-root.module';

@NgModule({
    declarations: [AppComponent, SnackBarComponent, EventNotAvailableComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'events-attendee-facing' }),
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgOptimizedImage,
        // MaterialModule,
        MatSnackBarModule,
        ReactiveFormsModule,
        NgxSkeletonLoaderModule,
        AngularSvgIconModule.forRoot(),
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => getAuth()),
        provideDatabase(() => getDatabase()),
        NgxGpAutocompleteModule,
        TranslocoRootModule,
    ],
    providers: [
        provideClientHydration(),
        CookieService,
        {
            provide: Loader,
            useValue: new Loader({
                apiKey: environment.googleMapsApiKey,
                libraries: ['places'],
            }),
        },
        {
            provide: IMAGE_LOADER,
            useValue: (config: ImageLoaderConfig) => {
                if (config.width && config.loaderParams?.['width'] * 2 <= config.width) {
                    return `${environment.GCS_URL}/sig/resize:${
                        _.keys(config.loaderParams).length ? config.loaderParams?.['type'] : 'force'
                    }:${config.loaderParams?.['width'] * 2}:${config.loaderParams?.['height'] * 2}/${config.src}`;
                }
                return `${environment.GCS_URL}/sig/resize:${
                    _.keys(config.loaderParams).length ? config.loaderParams?.['type'] : 'force'
                }:${config.loaderParams?.['width']}:${config.loaderParams?.['height']}/${config.src}`;
            },
        },
        {
            provide: IMAGE_CONFIG,
            useValue: {
                breakpoints: [16, 48, 96, 128, 384, 640, 750, 828, 1080, 1200, 1920],
            },
        },
        provideHttpClient(withFetch()),
        { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
        DecimalPipe,
        InitialPipe,
        FeedTimePipe,
        FeedLikesPipe,
        Location,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(@Inject(PLATFORM_ID) platformId: string) {
        if (!isPlatformBrowser(platformId)) {
            // console.log = function () {};
        }
    }
}
