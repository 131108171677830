/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import * as fs from 'filestack-js';
import { environment } from 'src/environments/environment';
// import * as fsa from 'filestack-adaptive';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs';
import { Buffer } from 'buffer';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class FilestackService {
    // initFileStack =
    client!: fs.Client;
    constructor(private http: HttpClient) {
        this.client = fs.init(environment.fileStackAPIKey);
    }

    setImgResolution(url: string, width: string, height: string) {
        height = height.includes('px') ? height.replace('px', '') : height;
        width = width.includes('px') ? width.replace('px', '') : width;

        return `https://cdn.filestackcontent.com/resize=width:${width},height:${height}/${this.imgUrl(url)}`;
    }

    setImgResolutionFitCrop(url: string, width: string, height: string) {
        height = height.includes('px') ? height.replace('px', '') : height;
        width = width.includes('px') ? width.replace('px', '') : width;

        return `https://cdn.filestackcontent.com/resize=width:${width},height:${height},fit:crop/${this.imgUrl(url)}`;
    }
    setImgResolutionFitClip(url: string, width: string, height: string) {
        height = height.includes('px') ? height.replace('px', '') : height;
        width = width.includes('px') ? width.replace('px', '') : width;

        return `https://cdn.filestackcontent.com/resize=width:${width},height:${height},fit:clip/${this.imgUrl(url)}`;
    }
    imgUrl(url: any) {
        return url.split('/').splice(-1)[0];
    }

    getBlobFormImageURL(url: string) {
        return this.http.get(`${environment.fcfApiUrl}/v2mime/image?url=${url}`, {
            responseType: 'blob', // Ensure the response type is blob for binary data
        });
    }

    urlsafeBase64Encode(url: string) {
        // Encode the URL to Base64
        let base64Url = btoa(url);
        // Make it URL-safe by replacing '+' with '-' and '/' with '_'
        base64Url = base64Url.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
        return base64Url;
    }

    //  to split the encoded URL every n characters
    splitEncodedUrl(URL: string, n: number, width: number, height: number, resizingType = 'force') {
        const encodedUrl = this.urlsafeBase64Encode(URL) as any;

        let splitUrl = encodedUrl.match(new RegExp('.{1,' + n + '}', 'g')).join('/');
        if (URL.includes(environment.GCS_URL)) {
            const fileNameURL = this.decodeUrlSafeBase64(URL.split('sig')[1].replace(/\//g, ''));
            const lastSlashIndex = fileNameURL.lastIndexOf('/');
            const fileName = this.decodeUrlSafeBase64(URL.split('sig')[1].replace(/\//g, ''))
                .substring(lastSlashIndex)
                .replace('/', '');

            if (this.isGif(fileName)) {
                return {
                    img:
                        URL.split('sig')[0] +
                        'sig/' +
                        `resize:${resizingType}:${width}:${height}:0` +
                        URL.split('sig')[1] +
                        '.gif',
                    imageName: URL.split('sig')[1] + '.gif',
                    type: resizingType || 'force',
                    width: width,
                    height: height,
                };
                // return (
                //     URL.split('sig')[0] +
                //     'sig/' +
                //     `resize:${resizingType}:${width}:${height}:0` +
                //     URL.split('sig')[1] +
                //     '.gif'
                // );
            } else {
                return {
                    img:
                        URL.split('sig')[0] +
                        'sig/' +
                        `resize:${resizingType}:${width * 2}:${height * 2}:0` +
                        URL.split('sig')[1],
                    imageName: URL.split('sig')[1],
                    type: resizingType || 'force',
                    width: width,
                    height: height,
                };

                // return (
                //     URL.split('sig')[0] +
                //     'sig/' +
                //     `resize:${resizingType}:${width * 2}:${height * 2}:0` +
                //     URL.split('sig')[1]
                // );
            }
        } else {
            return {
                img: `${environment.GCS_URL}/sig/resize:${resizingType}:${width * 2}:${height * 2}:0/${splitUrl}`,
                imageName: splitUrl,
                type: resizingType || 'force',
                width: width,
                height: height,
            };
            // return `${environment.GCS_URL}/sig/resize:${resizingType}:${width * 2}:${height * 2}:0/${splitUrl}`;
        }
    }

    isGif(imageUrl: string): boolean {
        return imageUrl.toLowerCase().endsWith('.gif');
    }

    getVideoStreamURL(url: string) {
        const fileNameURL = this.decodeUrlSafeBase64(url.split('sig')[1].replace(/\//g, ''));
        const lastSlashIndex = fileNameURL.lastIndexOf('/');
        const fileName = this.decodeUrlSafeBase64(url.split('sig')[1].replace(/\//g, ''))
            .substring(lastSlashIndex)
            .replace('/', '')
            .replace('/', '');
        return `${environment.fcfApiUrl}/v2mime/video?url=${encodeURIComponent(fileName)}`;
    }

    decodeUrlSafeBase64(encodedString: string) {
        // Replace '-' with '+' and '_' with '/'
        let normalizedEncodedString = encodedString.replace(/-/g, '+').replace(/_/g, '/');
        // Add padding if necessary
        const paddingLength = normalizedEncodedString.length % 4;
        if (paddingLength) {
            normalizedEncodedString += '='.repeat(4 - paddingLength);
        }
        // Decode the Base64 string
        const decodedBuffer = Buffer.from(normalizedEncodedString, 'base64');
        // Convert the buffer to a string
        return decodedBuffer.toString('utf8');
    }

    getVideoStream(url: string, range = 'bytes=0-') {
        const fileNameURL = this.decodeUrlSafeBase64(url.split('sig')[1].replace(/\//g, ''));
        const lastSlashIndex = fileNameURL.lastIndexOf('/');
        const fileName = this.decodeUrlSafeBase64(url.split('sig')[1].replace(/\//g, ''))
            .substring(lastSlashIndex)
            .replace('/', '')
            .replace('/', '');
        const headers = new HttpHeaders().set('Range', range);
        console.log(
            '${environment.fcfApiUrl}/v2mime/video/${encodeURI(fileName)}',
            `${environment.fcfApiUrl}/v2mime/video/${encodeURI(fileName)}`
        );
        return this.http
            .get(`${environment.fcfApiUrl}/v2mime/video/${encodeURI(fileName)}`, {
                headers,
                responseType: 'blob',
            })
            .pipe(
                map((resp) => {
                    return {
                        [url]: URL.createObjectURL(resp),
                    };
                })
            );
    }

    uploadFile(file: fs.InputFile) {
        const client = fs.init(environment.fileStackAPIKey);

        return new Promise((resolve, reject) => {
            client
                .upload(file)
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    }

    trasformImage(url?: string, resizeWidth?: number, resizeHeight?: number, radius = 1) {
        // console.log('url==========', url);
        let linkURL: any;

        // const transforms: fs.TransformOptions = {
        //     // transforms: {
        //     resize: {
        //         width: resizeWidth,
        //         height: resizeHeight,
        //     },
        //     rounded_corners: {
        //         radius,
        //     },
        //     // },
        // };

        if (!url) {
            return null;
        } else {
            linkURL = new window.URL(url);
        }

        if (linkURL.hostname !== 'cdn.filestackcontent.com') {
            return `https://cdn.filestackcontent.com/${environment.fileStackAPIKey}/resize=width:${resizeWidth},height:${resizeHeight}/rounded_corners=radius:${radius}/${url}`;
        } else {
            return `https://cdn.filestackcontent.com/resize=width:${resizeWidth},height:${resizeHeight}/rounded_corners=radius:${radius}/${this.imgUrl(
                url
            )}`;
        }
    }

    dowloadFile(url: string) {
        const handler = this.imgUrl(url);
        return `https://www.filestackapi.com/api/file/${handler}?dl=true`;
    }

    uploadFile2(file: any, name = '') {
        return new Promise((resolve, reject) => {
            const formData: any = {};
            if (typeof file === 'string' && file.includes('base64')) {
                formData['fileName'] = name;
                formData['image'] = file;
                formData['type'] = 'image/jpeg';

                this.http.post(`${environment.fcfApiUrl}/v2mime/upload-images`, formData).subscribe(
                    (event: any) => {
                        resolve({
                            ...event,
                            name: name,
                        });
                    },
                    (error) => {
                        console.error('Upload Error:', error);
                    }
                );
            } else {
                const fr = new FileReader();
                const image: any = file;
                fr.onload = () => {
                    const img = new Image() as any;
                    console.log('FR.Result');
                    img.src = fr.result;
                    // formData['file'] = file; //Not handling multipart/form-data for fastify update
                    const lastDotIndex = file.name.lastIndexOf('.');
                    const name = file.name.substring(0, lastDotIndex);
                    const extension = file.name.substring(lastDotIndex);

                    const timestamp = moment().valueOf();

                    // Construct new file name with timestamp
                    const newFileName = `${name}_${timestamp}${extension}`;

                    // Create a new File object with the same content and the new name
                    const renamedFile = new File([file], newFileName, { type: file.type });
                    formData['fileName'] = renamedFile.name;

                    formData['image'] = img.src;
                    formData['type'] = image.type;
                    this.http.post(`${environment.fcfApiUrl}/v2mime/upload-images`, formData).subscribe(
                        (event: any) => {
                            resolve({
                                ...event,
                                name: newFileName,
                            });
                        },
                        (error) => {
                            console.error('Upload Error:', error);
                        }
                    );
                };
                fr.readAsDataURL(image);
            }
        });
    }
}
