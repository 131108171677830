<div
    class="snackbar-wrapper"
    [ngClass]="{
        error: data.type === 'error',
        success: data.type === 'success',
        info: data.type === 'info',
        warning: data.type === 'warning'
    }"
>
    <div class="snackbar-text">
        <!-- <svg-icon class="success-icon" name="correct" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
            *ngIf="data.type === 'success'"></svg-icon> -->
        <!-- <svg-icon
            class="close-icon front-icon"
            name="error"
            [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
            *ngIf="data.type === 'error'"
        ></svg-icon> -->

        <div class="information" *ngIf="!data.action">
            <svg-icon
                class="first-icon"
                [src]="
                    data.type === 'success'
                        ? '../../../assets/icon-check.svg'
                        : data.type === 'warning'
                        ? '../../../assets/warning-icon.svg'
                        : data.type === 'error'
                        ? '../../../assets/error-icon.svg'
                        : data.type === 'info'
                        ? '../../../assets/icon-info.svg'
                        : ''
                "
            ></svg-icon>
            <div class="snack-msg">
                {{ data.message }}
            </div>
        </div>
    </div>

    <!-- <svg-icon class="close-icon" *ngIf="data.type === 'error'" (click)="dismissNotification()" name="green-cross-icon"
        alt=""></svg-icon> -->
    <svg-icon
        class="close-icon"
        (click)="dismissNotification()"
        src="../../../assets/green-cross-icon.svg"
        alt=""
    ></svg-icon>
</div>
