import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../components/snack-bar/snack-bar.component';

export const coerceToArray = <T>(value: T | T[]): T[] => (Array.isArray(value) ? value : [value]);

@Injectable({
    providedIn: 'root',
})
export class SnackBarService {
    constructor(private snackbar: MatSnackBar, private zone: NgZone) {}

    error(message: string, action?: string, duration?: number): void {
        this.passDataToSnackComponent(message, 'error', action, duration);
    }

    success(message: string, action?: string, duration?: number): void {
        this.passDataToSnackComponent(message, 'success', action, duration);
    }

    warning(message: string, action?: string, duration?: number): void {
        this.passDataToSnackComponent(message, 'warning', action, duration);
    }

    info(message: string, action?: string, duration?: number) {
        this.passDataToSnackComponent(message, 'info', action, duration);
    }
    private show(message: string, customConfig: MatSnackBarConfig = {}): void {
        const customClasses = coerceToArray(customConfig.panelClass).filter((v) => typeof v === 'string') as string[];

        this.zone.run(() => {
            this.snackbar.open(message, 'x', {
                ...customConfig,
                panelClass: ['snackbar-container', ...customClasses],
            });
        });
    }

    passDataToSnackComponent(message: string, type: string, action?: string, duration?: number) {
        this.snackbar.openFromComponent(SnackBarComponent, {
            data: {
                message,
                type,
                action,
            },
            duration: duration || 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
    }

    dismissSnackBar() {
        this.snackbar.dismiss();
    }
}
