import { Inject, Injectable } from '@angular/core';
import { AbstarckPage } from '../abstract-components/abstarck-page';
import { FirebaseService } from './firebase.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Choice, SubQue, feedBack } from '../models/feedBack.modal';
import { DataSnapshot } from '@angular/fire/compat/database/interfaces';
import { CustomerEventFeedbackTransformerService } from './customer-event-feedback-transformer.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { combineLatest, first, map, take } from 'rxjs';
import * as Excel from 'exceljs';
import { saveAs } from 'file-saver';

@Injectable({
    providedIn: 'root',
})
export class CustomerEventFeedbackService extends AbstarckPage {
    customerId = '';
    constructor(
        private Firebase: FirebaseService,
        db: AngularFireDatabase,
        transformer: CustomerEventFeedbackTransformerService
    ) {
        super(transformer, db);
    }

    fields = {
        feedback: [
            'order',
            'description',
            'type',
            'title',
            'slider_steps',
            'slider_low_value',
            'slider_high_value',
            'slider_custom_label_low',
            'slider_custom_label_high',
            // 'stars_amount',
            'question_required',
            'allow_multiple_answer',
            'range',
            'formType',
        ],
        feedbackChoice: ['feedback_form_id', 'title'],
        feedBackSubQue: [
            'type',
            'title',
            'slider_steps',
            'slider_low_value',
            'slider_high_value',
            'slider_custom_label_low',
            'slider_custom_label_high',
            // 'stars_amount',
            'choose_question',
            'feedback_form_id',
            'slider_steps',
            'slider_low_value',
            'slider_high_value',
            'range',
            'formType',
        ],
    };

    defaultObj = {
        slider_custom_label_high: '',
        slider_custom_label_low: '',
        slider_high_value: 10,
        question_required: true,
        allow_multiple_answer: false,
        slider_low_value: 0,
        slider_steps: 1,
        title: '',
        type: '',
        range: '1 - 10',
        description: '',
    };

    defaultChoices = [
        {
            title: '',
            feedback_form_id: '',
        },
        {
            title: '',
            feedback_form_id: '',
        },
    ];
    moment = moment;
    getEntity(entityId: string) {
        // let def = this.$q.defer();
        return new Promise((resolve) => {
            if (entityId === 'new') {
                const entity: any = Object.assign({}, this.defaultObj);
                entity.choices = [...this.defaultChoices];
                resolve(entity);
            } else {
                (this.getItem(entityId) as Promise<feedBack>).then((entity) => {
                    // entity = angular.extend(angular.copy(this.defaultObj), entity);
                    Promise.all([
                        this.getSubQuestion(entityId) as Promise<SubQue[]>,
                        this.getChoices(entityId) as Promise<Choice[]>,
                    ]).then((resp) => {
                        entity.choices = resp[1].length ? resp[1] : [...this.defaultChoices];
                        entity.sub_questions = resp[0].length ? resp[0] : [];
                        resolve(entity);
                    });
                    // (this.getChoices(entityId) as Promise<Choice[]>).then((choices) => {
                    //     entity.choices = choices.length ? choices : this.defaultChoices;
                    //     resolve(entity);
                    // });
                });
            }
        });
    }

    getChoices(id: string) {
        // let def = this.$q.defer();
        return new Promise((resolve) => {
            this.dataRef
                .child('forms')
                .child(id)
                .child('choices')
                .once('value')
                .then((snapshot) => {
                    resolve(this.toArray(snapshot, id));
                });
        });
    }

    getSubQuestion(id: string) {
        return new Promise((resolve) => {
            this.dataRef
                .child('forms')
                .orderByChild('feedback_form_id')
                .equalTo(id)
                .once('value')
                .then((snapshot) => {
                    resolve(this.toArray(snapshot));
                });
        });
    }

    toItem(snapshot: DataSnapshot, id: string) {
        const data = snapshot.val();
        data.id = id;
        return data;
    }

    toArray(snapshot: DataSnapshot, formId = '') {
        const data = snapshot.val();
        const result: Choice[] = [];
        if (this._.size(data)) {
            this._.forOwn(data, (val, key) => {
                val.id = key;
                val.feedback_form_id = formId;
                result.push(val);
            });
        }
        return result;
    }

    getItem(entityId: string) {
        // let def = this.$q.defer();
        return new Promise((resolve) => {
            this.dataRef
                .child('forms')
                .child(entityId)
                .once('value')
                .then((snapshot) => {
                    resolve(this.toItem(snapshot, entityId));
                });
        });
    }

    init(event_code: string, customerId: string, appId: string) {
        this.entityName = 'feedback';
        this.entityPathName = 'feedback';
        this.customerId = customerId;
        this.eventId = event_code;
        this.appId = appId;
        this.appRef = this.db.database.ref('customers').child(customerId);
        this.publicRef = this.appRef.child('eventsData');
        this.dataRef = this.publicRef.child(this.eventId).child('eventSetup').child('feedback');
        // this.listRef = this.publicRef.child(`/events_list/${event_code}`);
        this.transformer.setPageType('feedback');
    }

    override create(data: feedBack) {
        return new Promise((resolve, reject) => {
            this.dataRef.child('forms').once('value', (snapshot) => {
                const order = this._.size(snapshot.val()) || 0;
                data.order = order;
                delete data.id;
                data.formType = 'mainForm';
                const newKey = this.dataRef.child('forms').push().key as string;
                const choices = data.choices;
                const subQue = data.sub_questions;
                // console.log('data.subQues', subQue);
                data = this._.pick(data, this.fields.feedback) as any;
                // console.log('choice', choices, subQue);
                this.dataRef
                    .child('forms')
                    .child(newKey)
                    .update(data, () => {
                        data.id = newKey;
                        if (choices || subQue) {
                            const promises: any[] = [];
                            if (choices) {
                                choices.forEach((ch) => {
                                    if (ch.title) {
                                        promises.push(
                                            this.createChoice({
                                                title: ch.title,
                                                feedback_form_id: newKey,
                                            })
                                        );
                                    }
                                });
                            }

                            if (subQue) {
                                subQue.forEach((sq) => {
                                    this.createSubQue({
                                        // title: ch.title,
                                        ...sq,
                                        feedback_form_id: newKey,
                                    });
                                });
                            }
                            Promise.all(promises).then(() => {
                                resolve(data);
                            });
                        } else {
                            resolve(data);
                        }
                    })
                    .catch((error) => {
                        console.log('error', error);
                        reject(error);
                    });
            });
        });
    }

    override update(data: feedBack) {
        // let def = this.$q.defer();
        return new Promise((resolve, reject) => {
            const id = data.id as string;
            // const choices: Choice[] = data.choices;
            // const subQue = data.sub_questions;
            const _data: any = this._.pick(data, this.fields.feedback);
            this.dataRef
                .child('forms')
                .child(id)
                .update(_data, () => {
                    Promise.all([this.updateChoice(data), this.updateSubQuestions(data)])
                        .then((res) => {
                            resolve(res[1]);
                        })
                        .catch((error) => {
                            console.log('error', error);
                            reject();
                        });
                });
        });
    }

    updateChoice(data: feedBack) {
        const formId = data.id as string;
        const choices: Choice[] = data.choices;

        return new Promise((resolve) => {
            this.dataRef
                .child('forms')
                .child(formId)
                .child('choices')
                .once('value', (snapshot) => {
                    const dbChoices: string[] = this._.keys(snapshot.val());
                    //find items to delete
                    const choicesIds = choices.map((choice) => choice.id) || [];
                    dbChoices.forEach((ch) => {
                        if (choicesIds.indexOf(ch) === -1) {
                            this.dataRef.child('forms').child(formId).child('choices').child(ch).remove();
                        }
                    });
                    if (choices) {
                        choices.forEach((choice) => {
                            if (choice.id) {
                                this.dataRef
                                    .child('forms')
                                    .child(formId)
                                    .child('choices')
                                    .child(choice.id)
                                    .update({ title: choice.title });
                            } else {
                                const key = this.dataRef.child('forms').child(formId).child('choices').push()
                                    .key as string;
                                this.dataRef.child('forms').child(formId).child('choices').child(key).update({
                                    title: choice.title,
                                });
                                choice.id = key;
                            }
                        });
                        // resolve(_data);
                        resolve(true);
                    } else {
                        // resolve(_data);
                        resolve(true);
                    }
                });
        });
    }

    updateSubQuestions(data: feedBack) {
        const formId = data.id as string;
        const subQue = data.sub_questions as SubQue[];

        return new Promise((resolve) => {
            if (!data.allow_multiple_answer && data.type === 'CHOICE') {
                this.dataRef
                    .child('forms')
                    .orderByChild('feedback_form_id')
                    .equalTo(formId)
                    .once('value', (snapshot) => {
                        const dbSubQues: string[] = this._.keys(snapshot.val());
                        //find items to delete
                        const choicesIds = subQue.map((ques) => ques.id) || [];
                        dbSubQues.forEach((ques) => {
                            if (choicesIds.indexOf(ques) === -1) {
                                this.dataRef.child('forms').child(ques).remove();
                            }
                        });

                        if (subQue) {
                            subQue.forEach((ques) => {
                                if (ques.id) {
                                    ques.feedback_form_id = formId;
                                    this.dataRef.child('forms').child(ques.id).update(ques);
                                } else {
                                    const _suQues: any = this._.pick(ques, this.fields.feedBackSubQue);
                                    _suQues.formType = 'subQuestion';
                                    const key = this.dataRef.child('forms').push().key as string;
                                    this.dataRef.child('forms').child(key).update(_suQues);
                                    ques.id = key;
                                }
                            });
                            resolve(subQue);
                        } else {
                            resolve(true);
                        }
                    });
            } else {
                resolve(true);
            }
        });
    }

    createChoice(data: Choice) {
        return new Promise((resolve) => {
            const newKey = this.dataRef.child('forms').child(data.feedback_form_id!).child('choices').push()
                .key as string;
            const updateData = _.omit(data, ['feedback_form_id']);
            this.dataRef
                .child('forms')
                .child(data.feedback_form_id!)
                .child('choices')
                .child(newKey)
                .update(updateData, () => {
                    resolve(newKey);
                });
        });
    }

    createSubQue(data: SubQue) {
        data.formType = 'subQuestion';
        return new Promise((resolve) => {
            const newKey = this.dataRef.child('forms').push().key as string;
            this.dataRef
                .child('forms')
                .child(newKey)
                .update(data, () => {
                    resolve(newKey);
                });
        });
    }

    duplicate(entity: feedBack) {
        entity.title = entity.title + ' copy';
        delete entity?.id;
        return this.create(entity);
    }

    override remove(item: feedBack) {
        // console.log('item', item);
        return new Promise((resolve) => {
            // resolve(true);
            // let def = this.$q.defer();
            const id = item.id as string;
            // this.dataRef
            //     .child('feedback_choices')
            //     .orderByChild('feedback_form_id')
            //     .equalTo(id)
            //     .once('value', (snapshot) => {
            //         const choices = snapshot.val();
            //         if (choices) {
            //             this._.forOwn(choices, (val, key) => {
            //                 this.dataRef.child('feedback_choices').child(key).remove();
            //             });
            //         }
            //     });
            this.dataRef
                .child('answers')
                .orderByChild('feedback_form_id')
                .equalTo(id)
                .once('value', (snapshot) => {
                    const answers = snapshot.val();
                    if (answers) {
                        this._.forOwn(answers, (val, key) => {
                            this.dataRef.child('answers').child(key).remove();
                        });
                    }
                });
            this.dataRef
                .child('forms')
                .orderByChild('feedback_form_id')
                .equalTo(id)
                .once('value', (snapshot) => {
                    const subQue = snapshot.val();
                    if (subQue) {
                        this._.forOwn(subQue, (val, key) => {
                            this.dataRef.child('forms').child(key).remove();
                        });
                    }
                });
            this.dataRef
                .child('forms')
                .child(id)
                .remove(() => {
                    resolve(true);
                });
            this.reorderForms();
        });
        // return def.promise;
    }

    getAllItems(params: any = {}) {
        // jshint ignore:line
        // let def = this.$q.defer();
        return new Promise((resolve) => {
            // this.total = 0;
            this.dataRef
                .child('forms')
                .orderByChild('formType')
                .equalTo('mainForm')
                .once('value', (snapshot) => {
                    const items = this.toArray(snapshot);
                    // console.log('items', items);
                    if (!params.order) {
                        params.order = 'order|asc';
                    }

                    const resultObj = this.transformer.makeResponse(items, params);
                    // this.$log.debug(resultObj);
                    resolve(resultObj);
                });
        });
        // return def.promise;
    }
    reorderForms() {
        this.dataRef
            .child('forms')
            .orderByChild('formType')
            .equalTo('mainForm')
            .once('value', (snapshot) => {
                const forms = snapshot.val();
                if (forms) {
                    const currentOrder = [];
                    this._.forOwn(forms, (val, key) => {
                        currentOrder.push(forms[key].order);
                    });
                    let i = 0;
                    this._.forOwn(forms, (val, key) => {
                        this.dataRef.child('forms').child(key).update({ order: i });
                        i++;
                    });
                }
            });
    }

    deleteSubQuestion(id: string) {
        return new Promise((resolve, reject) => {
            if (id) {
                this.dataRef
                    .child('forms')
                    .child(id)
                    .remove()
                    .then(() => {
                        resolve(true);
                    });
            } else {
                resolve(true);
            }
        });
    }

    getAllFormWithAtttendeeAnswer(attendeeId: string) {
        return new Promise((resolve) => {
            this.dataRef
                .child('forms')
                .orderByChild('formType')
                .equalTo('mainForm')
                .once('value')
                .then((snapshot) => {
                    // console.log('snapshot', snapshot.val());
                    const formDataIds = this._.keys(snapshot.val());
                    // const forms: feedBack[] = [];

                    const promises: any[] = [];
                    formDataIds.forEach((id) => {
                        const form: feedBack = snapshot.val()[id];
                        form.id = id;
                        promises.push(this.getChoicesAndSubQuestions(id, form, attendeeId));
                    });

                    Promise.all(promises).then((forms) => {
                        // console.log('form', forms);
                        forms.sort((a, b) => a.order - b.order);

                        resolve(forms);
                    });

                    // resolve(this.toItem(snapshot, entityId));
                });
        });
    }

    getChoicesAndSubQuestions(id: string, form: feedBack, attendeeId: string) {
        return new Promise((resolve) => {
            Promise.all([
                this.getSubQuestion(id) as Promise<SubQue[]>,
                this.getChoices(id) as Promise<Choice[]>,
                this.Firebase.getByRefWhere(
                    `customers/${this.customerId}/eventsData/${this.eventId}/eventSetup/feedback/answers`,
                    'user_id',
                    attendeeId
                ) as any,
            ]).then((resp) => {
                form.choices = resp[1].length ? resp[1] : [];
                form.sub_questions = resp[0].length ? resp[0] : [];

                const answerIds = this._.keys(resp[2]);
                const formAnsId = answerIds.find((i: string) => resp[2][i].feedback_form_id === form.id) as any;

                // if(form.sub_questions.length)
                form.sub_questions.forEach((sq: SubQue) => {
                    const subFormId = answerIds.find((i: string) => resp[2][i].feedback_form_id === sq.id) as any;

                    if (subFormId?.length) {
                        sq.answer = resp[2][subFormId];
                        if (sq.answer) {
                            sq.answer.id = subFormId;
                        }
                    }
                });
                // console.log('form.sub_questions', form.sub_questions);

                if (formAnsId?.length) {
                    form.answer = resp[2][formAnsId];
                    if (form.answer) {
                        form.answer.id = formAnsId;
                    }
                }

                resolve(form);
            });
        });
    }

    updateAndCreateFeedBackAns(answers: any[], forms: feedBack[], attendeeId: string, isSubmitedForm = false) {
        return new Promise((resolve, reject) => {
            const promise: any[] = [];
            answers.forEach((ans: any) => {
                promise.push(this.createAndUpdateAns(ans));
            });

            Promise.all(promise).then((value: any) => {
                // const isAllRequiredFormSubmited = forms.every(
                //     (form: feedBack) => form.question_required && form.answer
                // );
                console.log('=====isSubmitedForm', isSubmitedForm);
                if (isSubmitedForm) {
                    // const key = this.dataRef.child('feedback_submitted').push().key as string;
                    // if (isAllRequiredFormSubmited) {
                    this.dataRef.child('submitted').update({
                        [attendeeId]: true,
                    });
                    resolve(value);
                    // } else {
                    //     this.dataRef.child('submitted').update({
                    //         [attendeeId]: false,
                    //     });
                    // }
                } else {
                    this.dataRef.child('submitted').update({
                        [attendeeId]: false,
                    });
                    resolve(value);
                }

                // console.log('value', value);
            });
        });
    }

    checkFeedBackSubmitted(attendeeId: string, customerId: string, eventId: string) {
        return this.Firebase.getByRef(
            `customers/${customerId}/eventsData/${eventId}/eventSetup/feedback/submitted/${attendeeId}`
        ).pipe(
            (take(1),
            map((data: any) => {
                // console.log('====data=====', data);
                return data;
            }))
        );
    }

    createAndUpdateAns(ans: any) {
        return new Promise((resolve) => {
            const key = ans.id || (this.dataRef.child('answers').push().key as string);

            // console.log('ans.id', ans.id);
            // console.log('key', key);
            const prepareData = this._.omit(ans, 'id');
            // console.log('data', prepareData);
            this.dataRef
                .child('answers')
                .child(key)
                .update(prepareData, () => {
                    ans.id = key;
                    resolve(ans);
                });
        });
    }

    // updateAns(ans: any) {
    //     return new Promise((resolve) => {
    //         const newKey = this.dataRef.child('feedback_answers').push().key as string;
    //         this.dataRef
    //             .child('feedback_answers')
    //             .child(ans.id)
    //             .update(ans, () => {
    //                 ans.id = newKey;
    //                 resolve(ans);
    //             });
    //     });
    // }

    updatefeedbackSettings(data: any, timeZone: string) {
        return new Promise((resolve, reject) => {
            const ref = `customers/${this.customerId}/eventsData/${this.eventId}/eventSetup/feedback/settings`;
            const newData = this.transformSettingsData(data, timeZone);
            const scheduleData = _.pick(newData, ['sendDateTimeUnix']);
            const reminders = _.pick(newData, ['reminders']);
            return this.Firebase.updateByRef(ref, scheduleData).then(() => {
                console.log(
                    this.moment(scheduleData.sendDateTimeUnix).format('YYYY-MM-DD h:mm:ss a'),
                    this.moment().format('YYYY-MM-DD h:mm:ss a')
                );
                if (this.moment(scheduleData.sendDateTimeUnix).diff(this.moment()) > 0) {
                    this.scheduleNotification(scheduleData, timeZone);
                }
                if ((reminders['reminders'] as any).length) {
                    _.forEach(reminders['reminders'], (val, index) => {
                        if (val.id) {
                            if (this.moment(val.sendDateTimeUnix).unix() > this.moment().unix())
                                this.updateInSheduledReminders(
                                    {
                                        sendDateTimeUnix: val.sendDateTimeUnix,
                                    },
                                    timeZone,
                                    val.scheduleId
                                ).then(() => {
                                    this.Firebase.updateByRef(`${ref}/reminders/${val.id}`, {
                                        sendDateTimeUnix: val.sendDateTimeUnix,
                                    });
                                });
                        } else {
                            this.updateInSheduledReminders({ sendDateTimeUnix: val.sendDateTimeUnix }, timeZone).then(
                                (scheduleKey) => {
                                    this.Firebase.pushByRef(`${ref}/reminders`, {
                                        sendDateTimeUnix: val.sendDateTimeUnix,
                                        scheduleId: scheduleKey,
                                    }).then((key) => {
                                        resolve({ id: key, scheduleId: scheduleKey });
                                    });
                                }
                            );
                        }
                    });
                } else {
                    resolve(null);
                }
            });
        });
    }

    updateInSheduledReminders(data: any, timeZone: string, scheduledKey = '') {
        return new Promise((resolve, reject) => {
            console.log('DATE', this.moment(data.sendDateTimeUnix).toDate().toISOString());
            if (scheduledKey) {
                this.Firebase.updateByRef(`schedule/events/${this.appId}_${this.eventId}_feedback_reminders`, {
                    _state: 'feedback_reminder',
                    app_id: this.appId,
                    event_id: this.eventId,
                    customer_id: this.customerId,
                });
                this.Firebase.updateByRef(
                    `schedule/events/${this.appId}_${this.eventId}_feedback_reminders/${scheduledKey}`,
                    {
                        // date: this.moment(data.sendDateTimeUnix).toDate().toISOString(),
                        date: this.moment(data.sendDateTimeUnix).format('YYYY-MM-DD HH:mm:ss'),
                        time_zone: timeZone,
                    }
                ).then(() => {
                    resolve(scheduledKey);
                });
            } else {
                this.Firebase.updateByRef(`schedule/events/${this.appId}_${this.eventId}_feedback_reminders`, {
                    _state: 'feedback_reminder',
                    app_id: this.appId,
                    event_id: this.eventId,
                    customer_id: this.customerId,
                });
                this.Firebase.pushByRef(`schedule/events/${this.appId}_${this.eventId}_feedback_reminders`, {
                    date: this.moment(data.sendDateTimeUnix).format('YYYY-MM-DD HH:mm:ss'),
                    time_zone: timeZone,
                }).then((key) => {
                    resolve(key);
                });
            }
        });
    }

    scheduleNotification(data: any, timeZone: string) {
        return new Promise((resolve, reject) => {
            console.log('DATE', this.moment(data.sendDateTimeUnix).format('YYYY-MM-DD HH:mm:ss'));
            this.db
                .object(`schedule/events/${this.appId}_${this.eventId}_feedback`)
                .set({
                    _state: 'feedback_notification',
                    app_id: this.appId,
                    event_id: this.eventId,
                    date: this.moment(data.sendDateTimeUnix).format('YYYY-MM-DD HH:mm:ss'),
                    time_zone: timeZone,
                    customer_id: this.customerId,
                })
                .then(() => resolve(true));
        });
    }

    getSettings() {
        const ref = `customers/${this.customerId}/eventsData/${this.eventId}/eventSetup/feedback/settings`;
        return this.Firebase.getByRef(ref).pipe(
            map((data: any) => {
                const retData: any = {};
                const reminders: any = [];
                if (data) {
                    retData.sendDateTimeUnix = data.sendDateTimeUnix;
                    if (data.reminders) {
                        _.forOwn(data.reminders, (val, key) => {
                            reminders.push({
                                ...val,
                                id: key,
                            });
                        });
                    }
                }
                retData.reminders = reminders;
                return retData;
            })
        );
    }

    deleteReminder(id: string, scheduleKey: string) {
        const ref = `customers/${this.customerId}/eventsData/${this.eventId}/eventSetup/feedback/settings/reminders/${id}`;
        this.Firebase.setByRef(`schedule/events/${this.appId}_${this.eventId}_feedback_reminders/${scheduleKey}`, null);
        return this.Firebase.setByRef(ref, null);
    }

    transformSettingsData(data: any, timeZone: string) {
        const retData = {
            // sendDateTimeUnix: data.sendDate
            //     ? this.moment(data.sendDate).utc(false).format('YYYY-MM-DD HH:mm:ss') + 'Z'
            //     : null,
            sendDateTimeUnix: data.sendDate ? this.moment(data.sendDate).format('YYYY-MM-DD HH:mm:ss') : null,
            reminders: data.reminders || [],
        };
        return retData;
    }

    orderForms(forms: string[]) {
        return new Promise((resolve, reject) => {
            const promises: any[] = [];

            forms.forEach((id, index) => {
                promises.push(this.updateOrder(id, index));
            });

            Promise.all(promises).then(() => {
                resolve(true);
            });
        });
    }

    updateOrder(swappingId: string, order: number) {
        return this.dataRef.child('forms').child(swappingId).update({ order: order });
    }

    exportFeedbacks() {
        return new Promise((resolve, reject) => {
            const ref = `/customers/${this.customerId}/eventsData/${this.eventId}/eventSetup/feedback`;
            combineLatest([this.Firebase.getByRef(ref + '/forms'), this.Firebase.getByRef(ref + '/answers')])
                .pipe(
                    map((res: any) => {
                        const forms = res[0];
                        const answers = res[1];
                        const choices: any = {};
                        const subQuestions: any = {};
                        const allForms: any = {};
                        const allChoices: any = {};
                        const allSubQuestions: any = {};
                        const allAnswers: any = {};
                        const allQuestionWithFormId: any = [];
                        if (forms) {
                            const formKeys = Object.keys(forms);
                            for (const key of formKeys) {
                                allQuestionWithFormId.push({ feedback_form_id: key, title: forms[key].title });
                                if (forms[key].type === 'CHOICE' && forms[key].choices) {
                                    _.forOwn(forms[key].choices, (value, choiceKey) => {
                                        choices[choiceKey] = { ...value, feedback_form_id: key };
                                    });
                                }
                                allForms[key] = { ...forms[key], choices: [], answers: [] };
                                if (forms[key].formType === 'subQuestion') {
                                    subQuestions[key] = forms[key];
                                }
                            }
                        }
                        if (subQuestions) {
                            const subQesKeys = Object.keys(subQuestions);
                            for (const key of subQesKeys) {
                                if (!allSubQuestions[subQuestions[key].feedback_form_id]) {
                                    allSubQuestions[subQuestions[key].feedback_form_id] = {};
                                }
                                if (!allSubQuestions[subQuestions[key].feedback_form_id][key]) {
                                    allSubQuestions[subQuestions[key].feedback_form_id][key] = {};
                                }
                                allSubQuestions[subQuestions[key].feedback_form_id][key] = {
                                    ...subQuestions[key],
                                    id: key,
                                };
                            }
                        }
                        console.log('ALL SUB QUESTIONS', allSubQuestions);
                        if (choices) {
                            const keys = Object.keys(choices);
                            for (const key of keys) {
                                allForms[choices[key].feedback_form_id].choices.push(choices[key].title);
                                allChoices[key] = choices[key].title;
                                if (allSubQuestions[choices[key].feedback_form_id]) {
                                    allForms[choices[key].feedback_form_id].hasSubQuestions = true;
                                }
                            }
                        }

                        if (allSubQuestions) {
                            const keys = Object.keys(allSubQuestions);
                            for (const key of keys) {
                                const subQuestForms: any = [];
                                if (allSubQuestions[key]) {
                                    const subQuestKeys = Object.keys(allSubQuestions[key]);
                                    for (const subKey of subQuestKeys) {
                                        if (allSubQuestions[key][subKey].feedback_form_id === key)
                                            subQuestForms.push({
                                                ...allSubQuestions[key][subKey],
                                                choices: [],
                                                answers: '',
                                            });
                                    }
                                }

                                allForms[key].subQuestions = subQuestForms;
                            }
                        }

                        if (answers) {
                            //Getting all answers with { feedback_form_id , answeer }
                            const keys = Object.keys(answers);
                            for (const key of keys) {
                                if (!answers[key].feedback_choices) {
                                    allAnswers[answers[key].feedback_form_id + answers[key].user_id] =
                                        answers[key].answer;
                                } else {
                                    const choiceKeys = Object.keys(answers[key].feedback_choices);
                                    let i = 0;
                                    for (const choiceKey of choiceKeys) {
                                        if (allForms[answers[key].feedback_form_id].formType === 'mainForm') {
                                            if (!allAnswers[answers[key].feedback_form_id + answers[key].user_id]) {
                                                allAnswers[answers[key].feedback_form_id + answers[key].user_id] = '';
                                            }
                                            allAnswers[answers[key].feedback_form_id + answers[key].user_id] =
                                                allAnswers[answers[key].feedback_form_id + answers[key].user_id] +
                                                choices[choiceKey].title +
                                                (i < choiceKeys.length - 1 ? ',' : '');
                                            i++;
                                        } else {
                                            if (!allAnswers[allForms.feedback_form_id + answers[key].user_id]) {
                                                allAnswers[answers[key].feedback_form_id + answers[key].user_id] = '';
                                            }
                                            allAnswers[answers[key].feedback_form_id + answers[key].user_id] =
                                                allAnswers[answers[key].feedback_form_id + answers[key].user_id] +
                                                choices[choiceKey].title +
                                                (i < choiceKeys.length - 1 ? ',' : '');
                                            i++;
                                        }
                                    }
                                }
                            }
                        }
                        _.forOwn(allForms, (val, formKey) => {
                            if (subQuestions[formKey]) {
                                delete allForms[formKey];
                            }
                        });

                        return { allAnswers, allQuestionWithFormId };
                    })
                )
                .pipe(first())
                .subscribe((data) => {
                    const promise: any = [];
                    if (this.appId) {
                        promise.push(
                            this.db.database
                                .ref(`/apps/${this.appId}/public/events_data/${this.eventId}/attendees`)
                                .once('value')
                        );
                    } else {
                        promise.push(
                            this.db.database
                                .ref(`/customers/${this.customerId}/eventsData/${this.eventId}/invitees`)
                                .once('value')
                        );
                    }
                    Promise.all(promise).then((res) => {
                        let attendeeData: any;
                        if (this.appId) attendeeData = res[0].val();
                        else {
                            const attendings: any = {};
                            _.forOwn(res[0].val(), (invitee, key) => {
                                if (invitee.status === 'Attending' || invitee.status === 'Checked In') {
                                    attendings[key] = {
                                        title: (invitee.firstName || '') + ' ' + (invitee.lastName || ''),
                                    };
                                }
                            });
                            attendeeData = attendings;
                        }
                        // console.log("ATTENDEE/INVITEE DATA", attendeeData)

                        const formFields = data.allQuestionWithFormId.map((field: any) => field.title);
                        const formIds = data.allQuestionWithFormId.map((field: any) => field.feedback_form_id);
                        // console.log("DATA TO BE PRINTED", data, formFields, formIds)

                        // const fields = ['Attendee', 'Question', 'Answer'];
                        this.generateSpreadSheet(attendeeData, formFields, formIds, data.allAnswers).then(() =>
                            resolve(true)
                        );
                    });
                });
        });
    }

    generateSpreadSheet(attendeeData: any, fields: string[], IdFields: string[], answers: any) {
        return new Promise((resolve) => {
            const workbook = new Excel.Workbook();
            const worksheet = workbook.addWorksheet('My Sheet', { properties: { tabColor: { argb: 'FFC0000' } } });
            worksheet.addRow(['Attendee', ...fields]);
            worksheet.getRow(1).font = { bold: true };
            worksheet.addRow(Array(IdFields.length).fill(' '));
            const attendees = _.keys(attendeeData);
            attendees.forEach((id: any) => {
                const row: any = [];
                row.push(this.toTitleCase(attendeeData[id].title));
                IdFields.forEach((feedback_form_id) => {
                    if (answers[feedback_form_id + id]) {
                        row.push(answers[feedback_form_id + id]);
                    } else {
                        row.push(' ');
                    }
                });
                worksheet.addRow(row);
            });
            worksheet.getColumn(1).width = 30;
            _.forEach(IdFields, (field, index) => {
                worksheet.getColumn(index + 2).width = 30;
            });
            workbook.xlsx.writeBuffer().then((buffer) => {
                const data: Blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                saveAs(data, 'feeback_report.xlsx');
                resolve(true);
            });
        });
    }

    toTitleCase(input: string): string {
        return !input || input.length === 0
            ? ''
            : input.replace(/\w\S*/g, (txt) => txt[0].toUpperCase() + txt.slice(1).toLowerCase());
    }
}
