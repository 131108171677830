<div class="page-wrapp" *ngIf="!isLoading">
    <div class="content-wrapp">
        <div class="header-wrapp">
            <div class="icon-wrap" *ngIf="!imgTree"></div>
            <picture *ngIf="imgTree">
                <img
                    [ngSrc]="imgTree.imageName"
                    priority
                    [width]="imgTree.width"
                    [height]="imgTree.height"
                    [loaderParams]="{
                        type: imgTree.type,
                        height: imgTree.height,
                        width: imgTree.width
                    }"
                />
            </picture>
            <h1 class="text text-25 bold">{{ customTitleCase(event.title) }}</h1>
        </div>

        <div class="form-wrapp">
            <div class="session-container">
                <!-- <div class="info"> -->
                <svg-icon src="../../assets/empty-state-icon.svg"></svg-icon>
                <h4 class="no-session-txt">The event you’re looking for is not available.</h4>
                <!-- </div> -->
            </div>
        </div>
    </div>
</div>
