import { provideTransloco, TranslocoModule } from '@jsverse/transloco';
import { NgModule } from '@angular/core';
import { TranslocoHttpLoader } from './transloco-loader';
// import { environment } from '../../home/dev/Documents/Events-Attendee/src/environments/environment';

@NgModule({
    exports: [TranslocoModule],
    providers: [
        provideTransloco({
            config: {
                availableLangs: ['en', 'en-za', 'es-es', 'de'],
                defaultLang: 'en',
                // Remove this option if your application doesn't support changing language in runtime.
                //   reRenderOnLangChange: true,
                //   prodMode: environment.production,
            },
            loader: TranslocoHttpLoader,
        }),
    ],
})
export class TranslocoRootModule {}
